import { Component, OnInit,HostListener } from '@angular/core';
import { ServiceService } from '../task/service.service';
@Component({
  selector: 'app-web',
  templateUrl: './web.component.html',
  styleUrls: ['./web.component.scss']
})
export class WebComponent implements OnInit {
  public viewSlide : boolean = true;
  constructor(private task:ServiceService) { }
  ngOnInit() {
    this.task.subscribe('vista:select', (data: any) => {
      const nav = document.querySelector('.bg-light');
      this.viewSlide = false;
      if(!this.viewSlide){
       // nav.classList.add('nav-custom');
       // nav.classList.remove('navbar-light');

       }else{
        // nav.classList.remove('nav-custom');
         //nav.classList.add('navbar-light');
       }
    });
  }
  @HostListener("scroll", ['$event'])
  doSomethingOnWindowScroll($event:Event){
    const nav = document.querySelector('.bg-light');
    let scrollOffset = $event.srcElement.children[0].scrollTop;
    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i)) {
     // window.open("https://apps.apple.com/mx/app/ishowyoumx/id1487878048");
      console.log('es movil');
    }else{
      if(scrollOffset >= 200){
        nav.classList.add('nav-custom');
        nav.classList.remove('navbar-light');
      }else{
        nav.classList.remove('nav-custom');
        nav.classList.add('navbar-light');
      }
    }
  }

}
