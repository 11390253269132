import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../../task/service.service'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { VistaComponent } from '../vista/vista.component';

@Component({
  selector: 'app-bodyroot',
  templateUrl: './bodyroot.component.html',
  styleUrls: ['./bodyroot.component.scss']
})
export class BodyrootComponent implements OnInit {
  public stage: any;
  public accion: any;
  public item: any;
  private estado: any;
  private idm: any;
  public zoom: number = 24;
  lat: number = 51.673858;
  lng: number = 7.815982;
  public galeria: Array<any> = [];
  public Array: Array<any> = [];
  public points: Array<any> = [];
  public informacion: Array<any> = [];
  constructor(private task: ServiceService, private modalService: NgbModal) {
    this.estado = 4;
    this.idm = 'en';
    this.consulta();
    this.stage = 'b'
    this.task.subscribe('vista:select', (data: any) => {
      this.accion = data;
      this.filter(data);
    });
    this.task.subscribe('ciudad:select', (data: any) => {
      this.estado = data;
      this.consulta(undefined, data).then(res => {
        this.filter(this.accion);
      });

    });

    //sabemos que idioma tenemos ahora
    this.task.subscribe('idm:select', (data: any) => {
      this.idm = data;
      this.consulta(data, undefined).then(res => {
        this.filter(this.accion);
      });

    });
  }
  async filter(data) {
    switch (data) {
      case 2:
        this.stage = 'g';
        this.setHref('web#galeria');
        this.galeria = await this.Array.filter(res => res['tipoCategorias'][0]['IdCategoria'] == data);
        break;
      case 5:
        this.stage = 'g';
        this.setHref('web#galeria');
        this.galeria = await this.Array.filter(res => res['tipoCategorias'][0]['IdCategoria'] == data);
        break;
      case 6:
        this.stage = 'g';
        this.setHref('web#galeria');
        this.galeria = await this.Array.filter(res => res['tipoCategorias'][0]['IdCategoria'] == data);
        break;
      case 7:
        this.stage = 'g';
        this.setHref('web#galeria');
        this.galeria = await this.Array.filter(res => res['tipoCategorias'][0]['IdCategoria'] == data);
        break;
      case 8:
        this.stage = 'g';
        this.setHref('web#galeria');
        this.galeria = await this.Array.filter(res => res['tipoCategorias'][0]['IdCategoria'] == data);
        break;
      case 9:
        this.stage = 'g';
        this.setHref('web#galeria');
        this.galeria = await this.Array.filter(res => res['tipoCategorias'][0]['IdCategoria'] == data);
        break;
      case 10:
        this.stage = 'g';
        this.setHref('web#galeria');
        this.galeria = await this.Array.filter(res => res['tipoCategorias'][0]['IdCategoria'] == data);
        break;
      case 11:
        this.stage = 'g';
        this.setHref('web#galeria');
        this.galeria = await this.Array.filter(res => res['tipoCategorias'][0]['IdCategoria'] == data);
        break;
      case 12:
        this.stage = 'g';
        this.setHref('web#galeria');
        this.galeria = await this.Array.filter(res => res['tipoCategorias'][0]['IdCategoria'] == data);
        break;
      case 13:
        this.stage = 'g';
        this.setHref('web#galeria');
        this.galeria = await this.Array.filter(res => res['tipoCategorias'][0]['IdCategoria'] == data);
        break;
      case 14:
        this.stage = 'g';
        this.setHref('web#galeria');
        this.galeria = await this.Array.filter(res => res['tipoCategorias'][0]['IdCategoria'] == data);
        break;
      case 'body':
        this.stage = 'b';
        break;
    }
  }
  async consulta(idioma?: any, ciudad?: any) {
    if (idioma !== undefined) {
      this.idm = idioma;
    }
    if (ciudad != undefined) {
      this.estado = ciudad;
    }
    this.Array = await this.task.get('/placesidm/city/' + this.estado + '/' + this.idm).then(async res => {
      return res['places'];
    }).catch(err => console.log(err));
    this.createMarker(this.Array);
  }
  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`)
  }

  mapClicked($event: MouseEvent) {
    this.markers.push({
      lat: this.lat,
      lng: this.lng,
      draggable: true
    });
  }
  createMarker(value) {
    value.forEach(element => {
      this.points.push({
        lat: parseFloat(element['ciudades'][0]['coordenadas']['latitud']),
        lng: parseFloat(element['ciudades'][0]['coordenadas']['longitud']),
        label: element['nombreNegocio'],
        iconUrl: '../../../assets/imagenes/sol2.png',
        draggable: true
      });
    });
  }
  markerDragEnd(m: marker, $event: MouseEvent) {
    console.log('dragEnd', m, $event);
  }
  markers: marker[] = this.points;

  setHref(value) {
    var timeout;
    timeout = setTimeout(function () {
      this.changeText = true;
      window.location.href = value;

    }, 1000);
  }
  ngOnInit() { }

  select(value) {
    this.informacion = value;
    this.lat = parseFloat(value['ciudades'][0]['coordenadas']['latitud']);
    this.lng = parseFloat(value['ciudades'][0]['coordenadas']['longitud']);
    this.stage = 'i';
    this.item = 'i';
  }
  selectItem(value) {
    this.item = value;
  }
 download() {
    this.task.movilDect();
 }
  openGa(data) {
    const modalRef = this.modalService.open(VistaComponent)
    modalRef.componentInstance.stage = this.stage
    //modalRef.componentInstance.fotos = this.galeria;
    modalRef.componentInstance.index = data;
    modalRef.result.then((result) => {

    }).catch((error) => {
      console.log(error);

    })
  }
}

// just an interface for type safety.
interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
