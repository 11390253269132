import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { ServiceService } from '../../task/service.service'
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  private login: FormGroup;
  private form ={};

  constructor( private route: Router,private formbuilder : FormBuilder, private  task : ServiceService,private modalClose : NgbActiveModal) {
    this.login = this.formbuilder.group({
      email :[null,[Validators.required,Validators.email]],
      password: [null,[Validators.required]],

    });
  }
  ngOnInit() {
  }
  loginAdmin(){
      this.route.navigate(['/admin']);
      console.log('se activo');
  }

}
