import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {apikey} from '../environments/environment'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './estructura/nav/nav.component';
import { SliderootComponent } from './estructura/slideroot/slideroot.component';
import { BodyrootComponent } from './estructura/bodyroot/bodyroot.component';
import { FooterComponent } from './footer/footer.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { VistaComponent } from './estructura/vista/vista.component';
import { AgmCoreModule } from '@agm/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContactoComponent } from './estructura/contacto/contacto.component';
import {FormBuilder, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { LoginComponent } from './estructura/login/login.component';
import { AdminComponent } from './admin/admin.component';
import { WebComponent } from './web/web.component';
import { MenuComponent } from './estructura/menu/menu.component';
import { NavadminComponent } from './estructura/navadmin/navadmin.component';
import { ComandappComponent } from './comandapp/comandapp.component';

import { LegalComponent } from './estructura/legal/legal.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    SliderootComponent,
    BodyrootComponent,
    FooterComponent,
    VistaComponent,
    ContactoComponent,
    LoginComponent,
    AdminComponent,
    WebComponent,
    MenuComponent,
    NavadminComponent,
    ComandappComponent,
    LegalComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
         provide: TranslateLoader,
         useFactory: customTranslateLoader,
         deps: [HttpClient]
      },
 }),
 AgmCoreModule.forRoot({
  // please get your own API key here:
  // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
  apiKey: apikey
})
  ],
  providers: [FormBuilder, ReactiveFormsModule, FormsModule],
  bootstrap: [AppComponent, VistaComponent, NavComponent, ContactoComponent, LoginComponent, ComandappComponent]
})
export class AppModule { }

export function customTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/traslate/', '.json');
}
