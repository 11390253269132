import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ServiceService } from '../../task/service.service'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ContactoComponent } from '../contacto/contacto.component';
import { appAndroid, appIOs} from '../../../environments/environment';
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  public ciudades: Array<any> = [];
  public viewButtonDownload: boolean= false;
  constructor(
    private translate: TranslateService,
    private task: ServiceService,
    private modalService: NgbModal
  ) {
    this.task.get('/ciudades').then(res => {
      this.ciudades = res['reservas'];
    }).catch(err => console.log(err));
  }

  ngOnInit() {
    this.task.movilDect();
  }

  private setIdm(value) {
    this.translate.use(value);
    this.task.publish('idm:select', value);
  }

  private vista(value) {
    this.task.publish('vista:select', value);
  }
  private ciudad(value) {
    this.task.publish('ciudad:select', value);
  }
  opencontact() {
    const modalRef = this.modalService.open(ContactoComponent);
    modalRef.result.then((result) => {
    }).catch((error) => {
      console.log(error);

    });
  }


}

