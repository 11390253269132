import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { ServiceService } from '../../task/service.service'
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {
  private contacto: FormGroup;
  public msg : any;
  public botonext: any;
  public form = {};
  constructor(private formbuilder : FormBuilder, private  task : ServiceService,private modalClose : NgbActiveModal) {
    this.contacto = this.formbuilder.group({
      email :[null,[Validators.required,Validators.email]],
      descripcion: [null,[Validators.required]],
      tel: [null,[Validators.required]] ,
    });
  this.msg='';
  this.botonext ='Cancelar';
  }
  ngOnInit() {
  }
  contactoSend(){
     this.task.notipost('/app/contacto/',{email: this.form['email'], tel: this.form['tel'], descripcion: this.form['descripcion'] +': se envio de Portal I Show You'}).then(res =>{
            this.msg= res['msg'];
            this.botonext ='Cerrar';
        }).catch(error =>console.log(error))
  }
  close(){
    this.modalClose.close();
  }
}
