import { Component, OnInit } from '@angular/core';
import { THROW_IF_NOT_FOUND } from '@angular/core/src/di/injector';
import { ServiceService} from '../../task/service.service'
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  private datos : Array<any>=[];
  private stage : any;
  constructor(private events : ServiceService) {
    this.stage = 'places'
  }

  ngOnInit() {
    this.events.subscribe('boton:select', (data: any) => {
        console.log(data);
   });
      this.events.getN('/places').then(res=>{
        this.datos= res['items']
        console.log(this.datos);
      }).catch(error=> console.log(error))
  }
consultas(value){
  switch(value){
    case 'usuarios' :
      //aqui llevaremos todos la
      this.events.getN('/user').then(res=>{
        this.datos= res['items']
        console.log(this.datos);
      }).catch(error=> console.log(error))
    break;
    default: 'No existe la accion'
  }

}

}
