// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};
export const servidor = 'https://demo1.cist.com.mx/ISY';
export const apikey ='AIzaSyB63sOmHXCoWzB8SoIGL2GOthKvlrxaIS4';
export const servidorN = 'http://localhost:3000/ISY';
export const SERVER_URL = 'https://demo2.cist.com.mx';
export const apicomnda ="https://apicomandapp.cist.com.mx/";
export const dataAnalitycs ="G-YW1PM1NRE1";
export const appAndroid = 'https://play.google.com/store/apps/details?id=com.opset.ishowyou&hl=es_MX&gl=US';
export const appIOs = 'https://apps.apple.com/mx/app/ishowyoumx/id1487878048';
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
