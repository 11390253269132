import { Injectable } from '@angular/core';
import {Subject, Subscription, Observable} from 'rxjs';
import {HttpClient,HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {servidor, servidorN, SERVER_URL, apicomnda, appAndroid, appIOs} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  private channels: { [key: string]: Subject<any>; } = {};
  constructor(private http : HttpClient) { }

  post(url,params?:any){
   return this.http.post(servidor+url,params).toPromise();
  }
  get(url,params?:any){

   return this.http.get(servidor+url,params).toPromise();
   }

   postN(url,params?:any){
    return this.http.post(servidorN+url,params).toPromise();
   }
   getN(url,params?:any){
    var token ='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c3VhcmlvIjoiMSIsImVtYWlsIjoidGVzdEBnbWFpbC5jb20iLCJpbWciOlt7InVybCI6Imh0dHBzOi8vaXNob3d5b3UtYXBwLWltYWdlLnMzLXVzLXdlc3QtMS5hbWF6b25hd3MuY29tL3Byb2ZpbGUvdGVzdC9pY29uLnBuZyJ9LHsidXJsIjoiaHR0cHM6Ly9pc2hvd3lvdS1hcHAtaW1hZ2UuczMtdXMtd2VzdC0xLmFtYXpvbmF3cy5jb20vcHJvZmlsZS90ZXN0L2ljb24ucG5nIn0seyJ1cmwiOiJodHRwczovL2lzaG93eW91LWFwcC1pbWFnZS5zMy11cy13ZXN0LTEuYW1hem9uYXdzLmNvbS9wcm9maWxlL3Rlc3QvaWNvbi5wbmcifSx7InVybCI6Imh0dHBzOi8vaXNob3d5b3UtYXBwLWltYWdlLnMzLXVzLXdlc3QtMS5hbWF6b25hd3MuY29tL3Byb2ZpbGUvdGVzdC9pY29uLnBuZyJ9XSwiY29udHJhc2VuYSI6InNoYTEkNWYxYmY1MzYkMSRiOWMyNjM3YzgwYjEyNjkwNTc4YTBhZjAyOTllODJmMWFjZjFjZjA1IiwiZGlyZWNjaW9uIjp7InBhaXMiOiJNZXhpY28iLCJjUG9zdCI6Ijc3NTAwIiwiZGlyZWNjaW9uIjoiY2FsbGUgMjAgc20gODkiLCJlc3RQIjoiUXVpbnRhbmEgcm9vIn0sImlhdCI6MTYyMDA2Njc1MywiZXhwIjoxNjIwMTA5OTUzfQ.RM4fJUR36jObRrYhS6YKtXgsuH-TmLBbrhdhJNo1OTc'
    let httpOptions = {
      headers: new HttpHeaders({
        'token': token
      })
    };
    return this.http.get(servidorN+url,httpOptions).toPromise();
    }

  notipost(url,params?:any){
    return this.http.post(SERVER_URL+url,params).toPromise();
  }
  newGet(url,params?:any){
     return this.http.get(apicomnda+url,params).toPromise();
  }
  subscribe(topic: string, observer: (_: any) => void): Subscription {
    if (!this.channels[topic]) {
        this.channels[topic] = new Subject<any>();
    }
    return this.channels[topic].subscribe(observer);
  }
  publish(topic: string, data: any): void {
    const subject = this.channels[topic];
    if (!subject) {
        // Or you can create a new subject for future subscribers
        return;
    }
    subject.next(data);
  }

  movilDect() {
    if (navigator.userAgent.match(/Android/i)) {
      window.open(appAndroid);
    }
    if (navigator.userAgent.match(/iPhone/i)) {
      window.open(appIOs);
    }
    // (navigator.userAgent.match(/iPod/i)) ||
    if (navigator.userAgent.match(/iPad/i)) {
      window.open(appIOs);
    }
  }
}
