import { Component, OnInit,Input } from '@angular/core';
import {NgbModal,NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-vista',
  templateUrl: './vista.component.html',
  styleUrls: ['./vista.component.scss']
})
export class VistaComponent implements OnInit {
  public url : any;
  @Input() public fotos;
  @Input() public index;
  @Input() public stage;
  constructor(private modalClose : NgbActiveModal) {
    this.url= this.index
  }
  ngOnInit() {
    this.url= this.index
    //this.url  = this.fotos['imagenesVista'][this.index]
  }

  imgModal(){
    let text;
     text = "background-size: cover; background-position: center center;height: 0;padding-bottom:calc(100vh - 75px);overflow: hidden;";
     return text;
  }
  close(){
    this.modalClose.close('test');
  }

}
