import { Component, OnInit } from '@angular/core';
import {ActivatedRoute,Params} from '@angular/router';
import {VistaComponent} from '../estructura/vista/vista.component';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {ServiceService} from '../task/service.service';

import {
  DomSanitizer
} from '@angular/platform-browser';

@Component({
  selector: 'app-comandapp',
  templateUrl: './comandapp.component.html',
  styleUrls: ['./comandapp.component.scss']
})
export class ComandappComponent implements OnInit {
  public rest : Array<any>=[];
  public comida : Array<any>=[];
  public bebida : Array<any>=[];
  public postre : Array<any>=[];
  public entrada : Array<any>=[];
  constructor(private sanitization:DomSanitizer,private route : ActivatedRoute,private modalService : NgbModal, private http : ServiceService) {
    this.route.queryParams.subscribe((params: Params) => {
      console.log(params);

      this.http.newGet('comandapp/public/menu/'+params['id']).then(res=>{
        this.rest= res['items'];
        this.comida = this.rest.filter(word => word.categoria=='Plato Fuerte');
        this.bebida = this.rest.filter(word => word.categoria=='Bebida');
        this.entrada = this.rest.filter(word => word.categoria=='Entrada');
        this.postre = this.rest.filter(word => word.categoria=='Postre');
      }).catch(error=>console.log(error))
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params: Params) => {
      this.http.newGet('comandapp/public/menu/'+params['id']).then(res=>{
        console.log(res);
        this.rest= res['items'];
        this.comida = this.rest.filter(word => word.categoria=='Plato Fuerte');
        this.bebida = this.rest.filter(word => word.categoria=='Bebida');
        this.entrada = this.rest.filter(word => word.categoria=='Entrada');
        this.postre = this.rest.filter(word => word.categoria=='Postre');
      }).catch(error=>console.log(error))
    });
  }
  urlCard(value){
    let estilo = this.sanitization.bypassSecurityTrustStyle("background-image:url("+value+"); background-size: cover; background-position: center center; min-width: 100px; max-height: 150px;max-height: 150px;  position: relative;height: 0; padding-bottom: 50%; overflow: hidden;border-radius: 5px;");
    return estilo;
  }
  openGa(data){
    const modalRef= this.modalService.open(VistaComponent)
         modalRef.componentInstance.stage = ''
         //modalRef.componentInstance.fotos = this.galeria;
         modalRef.componentInstance.index = data;
         modalRef.result.then((result) => {

        }).catch((error) => {
          console.log(error);

        })
  }
}
