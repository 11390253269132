import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, filter  } from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import { dataAnalitycs} from '../environments/environment';
declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'I Show You';
  public activeLang = 'en';
  public admin : any;

  constructor(private translate: TranslateService,private router: Router){
    this.translate.setDefaultLang(this.activeLang);
    this.admin =1;
  }
  ngOnInit() {
    this.setUpAnalytics();
  }

  setUpAnalytics() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        gtag('config', dataAnalitycs,
          {
            page_path: event.urlAfterRedirects
          }
        );
      });
  }
  //@HostListener("scroll", ['$event'])
 /* doSomethingOnWindowScroll($event:Event){
    const nav = document.querySelector('.bg-light')
    let scrollOffset = $event.srcElement.children[0].scrollTop;

     if(scrollOffset >= 200){
      nav.classList.add('nav-custom');
      nav.classList.remove('navbar-light');

     }else{
       nav.classList.remove('nav-custom');
       nav.classList.add('navbar-light');
     }
  }*/

}
