import { Component, OnInit } from '@angular/core';
import { ServiceService} from '../../task/service.service'
@Component({
  selector: 'app-navadmin',
  templateUrl: './navadmin.component.html',
  styleUrls: ['./navadmin.component.scss']
})
export class NavadminComponent implements OnInit {
private ciudades : Array<any> = [];
  constructor(private events : ServiceService) {
    
    this.events.get('/ciudades').then(res =>{
      this.ciudades= res['reservas'];
    }).catch(err => console.log(err));
   }

  ngOnInit() {
  }

  selectBoton(value){
    this.events.publish('boton:select',value);
  }

}
