import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from '../app/admin/admin.component'
import { WebComponent } from '../app/web/web.component'
import { ComandappComponent } from './comandapp/comandapp.component'
import {LegalComponent} from './estructura/legal/legal.component';


const routes: Routes = [
  { path: '', redirectTo: '/web#galeria', pathMatch: 'full' },
  { path: 'web', component: WebComponent },
  { path: 'admin', component: AdminComponent },
  { path: 'comandapp', component: ComandappComponent },
  /* path: 'cortecaja', component: RptCorteCajaComponent },
  { path: 'responsiva', component: ResponsivaComponent },
  { path: 'SaveCupon', component: SaveCuponComponent },
  { path: 'ListReserva', component: ListReservaComponent },
  { path: 'ListCheck', component: ListCheckComponent },
  { path: 'ReportDetallepago', component: ReportDetallepagoComponent },
  { path: 'ResumePago', component: ResumePagoComponent }*/
  { path: 'aviso', component: LegalComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
