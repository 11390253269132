import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent} from '../estructura/login/login.component'
import { ContactoComponent} from '../estructura/contacto/contacto.component';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private modalService : NgbModal) { }

  ngOnInit() {
  }
  opencontact(){
    const modalRef= this.modalService.open(ContactoComponent)
         modalRef.result.then((result) => {

        }).catch((error) => {
          console.log(error);

        })
  }
  openLogin(){
    const modalRef= this.modalService.open(LoginComponent)
   // modalRef.componentInstance.stage = this.stage
    //modalRef.componentInstance.fotos = this.galeria;
   // modalRef.componentInstance.index = data;
    modalRef.result.then((result) => {
    
   }).catch((error) => {
     console.log(error);

   })
  }

}
